import React, { useState } from 'react';
import * as Yup from 'yup';
import omitBy from 'lodash/omitBy';

import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';

import {
  USE_OF_FUNDS_OPTIONS,
  NAICS_INDUSTRY_GROUP_SELECT_OPTIONS,
  REVENUE_MODEL_V6_SELECT_OPTIONS,
  Application,
  CAPITAL_DEPLOYMENT_OPTIONS,
  Company,
} from '@hum/types';
import {
  Box,
  Form,
  TextInputField,
  SelectInputField,
  SelectOption,
  InputMode,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

import {
  BUSINESS_TYPE_SELECT_OPTIONS,
  EBITDA_BURN_OPTIONS,
  ECONOMIC_TIER_SELECT_OPTIONS,
  EQUITY_RAISED_SELECT_OPTIONS,
  EXISTING_DEBT_SELECT_OPTIONS,
  FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS,
  COMPANY_HEADCOUNT_SELECT_OPTIONS,
  LEVERAGEABLE_ASSETS_SELECT_OPTIONS,
  REQUIRED_INSIGHTS_SELECT_OPTIONS,
  MARGINS_OPTIONS,
  MONTHS_OF_RUNWAY_SELECT_OPTIONS,
  PAYMENT_PROCESSOR_SELECT_OPTIONS,
  SYSTEM_RECORD_SELECT_OPTIONS,
  TARGET_DEBT_RAISE_SELECT_OPTIONS,
  YEAR_OVER_YEAR_GROWTH_RATE_SELECT_OPTIONS,
  LAST_FUNDING_ROUND_OPTIONS,
} from '@hum/icm-app/src/components/constants';

import { adminEditCompanyFormClosed } from '../../../../actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { updateCapitalApplication, useApiMutation } from '@hum/api';

const formatPayload = (payload: UpdateCapitalApplicationInformationPayload) => {
  const application = payload['application'];

  const multiSelectValues = outputApplicationMultiSelectValues(
    {
      ...application,
      leverageableAssets: application['leverageableAssets'] || [],
      requiredInsights: application['requiredInsights'] || [],
    },
    ['leverageableAssets', 'requiredInsights']
  );
  const applicationPayload = omitBy(
    normalize.application.out({
      ...application,
      ...multiSelectValues,
      ...{
        yearFounded: payload.application
          ? Number(payload.application.yearFounded)
          : undefined,
      },
    }),
    (value, key) =>
      ['year_founded', 'last_funding_round'].includes(key) && !value
  );

  return {
    company: normalize.company.out(payload.company),
    application: applicationPayload,
  };
};
type UpdateCapitalApplicationInformationPayload = {
  company: Pick<Company, 'businessType' | 'country'>;
  application: Pick<
    Application,
    | 'yearlyRevenue'
    | 'monthsOfRunway'
    | 'recordSystem'
    | 'paymentProcessor'
    | 'industryVertical'
    | 'selfReportedIndustry'
    | 'revenueModel'
    | 'totalEquityRaised'
    | 'yearOverYearGrowthRate'
    | 'margins'
    | 'ebitdaBurn'
    | 'existingDebt'
    | 'targetDebtRaise'
    | 'lastFundingRound'
    | 'yearFounded'
    | 'useOfFunds'
    | 'fundraisingTimeFrame'
    | 'numberOfEmployees'
    | 'latestPostMoneyValuation'
    | 'leverageableAssets'
    | 'requiredInsights'
    | 'capitalDeployment'
  >;
};

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    businessType: Yup.string().nullable(),
    country: Yup.string().nullable(),
  }),
  application: Yup.object().shape({
    yearlyRevenue: Yup.string()
      .typeError('Please select an option')
      .required('Required'),
    monthsOfRunway: Yup.string()
      .typeError('Please select an option')
      .required('Required'),
    recordSystem: Yup.string().nullable(),
    paymentProcessor: Yup.string().nullable(),
    industryVertical: Yup.string().nullable(),
    revenueModel: Yup.string().nullable(),
    totalEquityRaised: Yup.string().nullable(),
    //tractionLtmRevenue: Yup.string().nullable(),
    yearOverYearGrowthRate: Yup.string().nullable(),
    margins: Yup.string().nullable(),
    ebitdaBurn: Yup.string().nullable(),
    existingDebt: Yup.string().nullable(),
    fundraisingTimeFrame: Yup.string().nullable(),
    numberOfEmployees: Yup.string().nullable(),
    latestPostMoneyValuation: Yup.string().nullable(),
    leverageableAssets: Yup.array().notRequired().nullable(),
    requiredInsights: Yup.array().notRequired().nullable(),
    targetDebtRaise: Yup.string().nullable(),
    investmentAmount: Yup.number()
      .integer('Use whole numbers without notations like 1K, 1M, 1B')
      .typeError('Please enter a whole number')
      .min(1, 'Please enter an amount greater than $0')
      .max(
        999999999,
        'Please enter an amount less than or equal to $999,999,999'
      )
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    lastFundingRound: Yup.string().nullable(),
    yearFounded: Yup.string().nullable(),
    useOfFunds: Yup.string().nullable(),
    capitalDeployment: Yup.string().nullable(),
  }),
});

interface UpdateCapitalApplicationInformationProps {
  company: Company;
  onSuccess: () => void;
  onCancel: () => void;
  'data-testid': string;
}

const getSelectedValuesFromSelectOption = (options: SelectOption[]) => {
  return options.map((option) => option.value);
};

const outputApplicationMultiSelectValues = (
  application: UpdateCapitalApplicationInformationPayload['application'],
  toValues: string[]
) => {
  const formattedValues = toValues
    .map((key) => ({
      [key]: getSelectedValuesFromSelectOption(application[key]),
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});
  return {
    ...application,
    ...formattedValues,
  };
};
const prepareForUpdate = (company: Partial<Company> = {}) => {
  return {
    company: {
      businessType: company.businessType,
      country: company.country,
    },
    application: {
      yearlyRevenue: company.application!.yearlyRevenue,
      monthsOfRunway: company.application!.monthsOfRunway,
      recordSystem: company.application!.recordSystem,
      paymentProcessor: company.application!.paymentProcessor,
      industryVertical: company.application!.industryVertical,
      selfReportedIndustry: company.application!.selfReportedIndustry,
      revenueModel: company.application!.revenueModel,
      totalEquityRaised: company.application!.totalEquityRaised,
      yearOverYearGrowthRate: company.application!.yearOverYearGrowthRate,
      margins: company.application!.margins,
      ebitdaBurn: company.application!.ebitdaBurn,
      existingDebt: company.application!.existingDebt,
      targetDebtRaise: company.application!.targetDebtRaise,
      investmentAmount: company.application!.investmentAmount,
      lastFundingRound: company.application!.lastFundingRound,
      yearFounded: company.application!.yearFounded,
      fundraisingTimeFrame: company.application!.fundraisingTimeFrame,
      numberOfEmployees: company.application!.numberOfEmployees,
      latestPostMoneyValuation: company.application!.latestPostMoneyValuation,
      leverageableAssets: company.application!.leverageableAssets,
      requiredInsights: company.application!.requiredInsights,
      useOfFunds: company.application!.useOfFunds,
      capitalDeployment: company.application!.capitalDeployment,
    },
  };
};

export const CapitalApplication: React.FC<UpdateCapitalApplicationInformationProps> = ({
  company: currentCompany,
  onSuccess,
  onCancel,
  'data-testid': dataTestId,
}) => {
  const flags = useFlags();
  const { mutate: updateApplication } = useApiMutation(
    updateCapitalApplication,
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        onSuccess();
        setLoading(false);
      },
    }
  );
  const [loading, setLoading] = useState(false);
  const dynamicValidationSchema = () => {
    return validationSchema.shape({
      application: Yup.object().shape({
        yearlyRevenue: Yup.string()
          .typeError('Please select an option')
          .required('Required'),
        monthsOfRunway: Yup.string()
          .typeError('Please select an option')
          .required('Required'),
        recordSystem: Yup.string().nullable(),
        paymentProcessor: Yup.string().nullable(),
        industryVertical: Yup.string().nullable(),
        revenueModel: Yup.string().nullable(),
        totalEquityRaised: Yup.string().nullable(),
        selfReportedIndustry: Yup.string()
          .typeError('Please select an option')
          .required('Required'),
        yearOverYearGrowthRate: Yup.string().nullable(),
        margins: Yup.string().nullable(),
        ebitdaBurn: Yup.string().nullable(),
        existingDebt: Yup.string().nullable(),
        fundraisingTimeFrame: Yup.string().nullable(),
        numberOfEmployees: Yup.string().nullable(),
        latestPostMoneyValuation: Yup.string().nullable(),
        leverageableAssets: Yup.array().notRequired().nullable(),
        requiredInsights: Yup.array().notRequired().nullable(),
        targetDebtRaise: Yup.string().nullable(),
        investmentAmount: Yup.number()
          .integer('Use whole numbers without notations like 1K, 1M, 1B')
          .typeError('Please enter a whole number')
          .min(1, 'Please enter an amount greater than $0')
          .max(
            999999999,
            'Please enter an amount less than or equal to $999,999,999'
          )
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null)),
        lastFundingRound: Yup.string().nullable(),
        yearFounded: Yup.string().nullable(),
        useOfFunds: Yup.string().nullable(),
        capitalDeployment: Yup.string().nullable(),
      }),
    });
  };
  const { dispatch } = useAppStore();
  const updateInfo = async (
    payload: UpdateCapitalApplicationInformationPayload
  ) => {
    try {
      setLoading(true);
      const companyId = currentCompany.id;
      const response = await backend.patch(
        `/companies/${companyId}/capital_application`,
        formatPayload(payload)
      );

      if (response.company?.id) {
        dispatch(adminEditCompanyFormClosed({ companyId: currentCompany.id }));
        toast.success('Updated general information successfully');
        onSuccess();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to update company information.');
    } finally {
      setLoading(false);
    }
  };
  const initialValues = React.useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);

  const form = useForm({
    initialValues,
    validationSchema: dynamicValidationSchema(),
    onSubmit: (payload) =>
      flags.enabled('use-react-query')
        ? updateApplication({
            companyId: currentCompany.id,
            ...(formatPayload(
              payload
            ) as UpdateCapitalApplicationInformationPayload),
          })
        : updateInfo(payload),
  });

  return (
    <div data-testid={dataTestId}>
      <Form onSubmit={form.handleSubmit}>
        <SelectInputField
          label="Business Type"
          inputAriaLabel="Business Type"
          inputTestId="business-type"
          options={BUSINESS_TYPE_SELECT_OPTIONS}
          {...useField('company.businessType', 'select', form).input}
        />

        <SelectInputField
          label="Last 12 Months' Revenue"
          inputAriaLabel="Yearly revenue"
          placeholder=""
          inputTestId="yearly-revenue"
          options={ECONOMIC_TIER_SELECT_OPTIONS}
          {...useField('application.yearlyRevenue', 'select', form).input}
        />

        <SelectInputField
          label="Months of runway (eg. cash / monthly burn)"
          inputAriaLabel="Months of runway"
          placeholder=""
          inputTestId="months-of-runway"
          options={MONTHS_OF_RUNWAY_SELECT_OPTIONS}
          {...useField('application.monthsOfRunway', 'select', form).input}
        />
        <SelectInputField
          label="What accounting system do you use?"
          inputAriaLabel="What accounting system do you use?"
          inputTestId="accounting-system"
          options={SYSTEM_RECORD_SELECT_OPTIONS}
          isClearable
          isCreatable
          {...useField('application.recordSystem', 'select', form).input}
        />

        <SelectInputField
          label="What payment processor do you use?"
          inputAriaLabel="What payment processor do you use?"
          inputTestId="payment-processor"
          options={PAYMENT_PROCESSOR_SELECT_OPTIONS}
          isClearable
          isCreatable
          {...useField('application.paymentProcessor', 'select', form).input}
        />
        <SelectInputField
          label="Industry Vertical"
          inputAriaLabel="Industry vertical"
          inputTestId="industry-vertical"
          options={BUSINESS_TYPE_SELECT_OPTIONS}
          isClearable
          {...useField('application.industryVertical', 'select', form).input}
        />
        <SelectInputField
          label="Self-reported NAICS industry"
          inputAriaLabel="Self-reported industry"
          inputTestId="self-reported-industry"
          options={NAICS_INDUSTRY_GROUP_SELECT_OPTIONS}
          isClearable
          {...useField('application.selfReportedIndustry', 'select', form)
            .input}
        />
        <SelectInputField
          label="Revenue model"
          inputAriaLabel="Revenue model"
          inputTestId="revenue-model"
          options={REVENUE_MODEL_V6_SELECT_OPTIONS}
          isClearable
          {...useField('application.revenueModel', 'select', form).input}
        />
        <SelectInputField
          label="Total equity raised"
          inputAriaLabel="Total equity raised"
          inputTestId="total-equity-raised"
          options={EQUITY_RAISED_SELECT_OPTIONS}
          isClearable
          {...useField('application.totalEquityRaised', 'select', form).input}
        />
        <SelectInputField
          label="Year over year growth rate"
          inputAriaLabel="Year over year growth rate"
          inputTestId="year-over-year-growth-rate"
          options={YEAR_OVER_YEAR_GROWTH_RATE_SELECT_OPTIONS}
          isClearable
          {...useField('application.yearOverYearGrowthRate', 'select', form)
            .input}
        />
        <SelectInputField
          label="Gross margin"
          inputAriaLabel="Gross margin"
          inputTestId="gross-margin"
          options={MARGINS_OPTIONS}
          isClearable
          {...useField('application.margins', 'select', form).input}
        />
        <SelectInputField
          label="Last 12 month (LTM) EBITDA"
          inputAriaLabel="Last 12 month (LTM) EBITDA"
          inputTestId="last-12-month-ltm-ebitda"
          options={EBITDA_BURN_OPTIONS}
          isClearable
          {...useField('application.ebitdaBurn', 'select', form).input}
        />
        <SelectInputField
          label="Existing debt"
          inputAriaLabel="Existing debt"
          inputTestId="existing-debt"
          options={EXISTING_DEBT_SELECT_OPTIONS}
          isClearable
          {...useField('application.existingDebt', 'select', form).input}
        />
        <SelectInputField
          label={'Target capital raise - Deprecated'}
          inputAriaLabel="Target raise"
          inputTestId="target-debt-raise"
          options={TARGET_DEBT_RAISE_SELECT_OPTIONS}
          isClearable
          {...useField('application.targetDebtRaise', 'select', form).input}
          disabled={true}
        />
        <TextInputField
          {...useField('application.investmentAmount', 'text', form).input}
          inputTestId="target-raise-amount"
          aria-label="Target raise amount"
          label="Target raise amount"
          inputMode={InputMode.DOLLAR}
        />
        <SelectInputField
          label="Most recent financing round"
          inputAriaLabel="Most recent financing round"
          options={LAST_FUNDING_ROUND_OPTIONS}
          {...useField('application.lastFundingRound', 'select', form).input}
        />
        <TextInputField
          {...useField('application.yearFounded', 'text', form).input}
          aria-label="Year Founded"
          label="Year Founded"
        />
        <SelectInputField
          label="Fundraising Time Frame"
          inputAriaLabel="Fundraising Time Frame"
          options={FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS}
          {...useField('application.fundraisingTimeFrame', 'select', form)
            .input}
        />
        <SelectInputField
          label="Leverageable assets"
          inputAriaLabel="Leverageable assets"
          options={LEVERAGEABLE_ASSETS_SELECT_OPTIONS}
          isMulti
          {...useField('application.leverageableAssets', 'select', form).input}
        />
        <SelectInputField
          label="Required insights"
          inputAriaLabel="Required insights"
          options={REQUIRED_INSIGHTS_SELECT_OPTIONS}
          isMulti
          {...useField('application.requiredInsights', 'select', form).input}
        />

        <TextInputField
          inputMode={InputMode.DOLLAR}
          label="Latest post money valuation"
          {...useField('application.latestPostMoneyValuation', 'text', form)
            .input}
        />
        <SelectInputField
          label="Number of employees"
          inputAriaLabel="Number of employees"
          options={COMPANY_HEADCOUNT_SELECT_OPTIONS}
          {...useField('application.numberOfEmployees', 'select', form).input}
        />
        <SelectInputField
          label="Use of funds"
          inputTestId="use-of-funds"
          data-testid="useOfFunds"
          {...useField('application.useOfFunds', 'text', form).input}
          options={USE_OF_FUNDS_OPTIONS}
        />
        <SelectInputField
          label="Capital deployment"
          inputTestId="capital-deployment"
          data-testid="capitalDeployment"
          {...useField('application.capitalDeployment', 'text', form).input}
          options={CAPITAL_DEPLOYMENT_OPTIONS}
        />

        <Box justifySpaceBetween>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => {
              form.resetForm();
              onCancel();
            }}
            disabled={loading || form.isSubmitting}
            testId="update-company-information-tabs:capital_application_information_tab:body:cancel-button"
          >
            Cancel
          </Button>

          <Button
            submit
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
            testId="update-company-information-tabs:capital_application_information_tab:body:submit-button"
          >
            Update
          </Button>
        </Box>
      </Form>
    </div>
  );
};
