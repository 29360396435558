import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';
import * as Yup from 'yup';

import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';

import {
  Box,
  Form,
  TextInputField,
  SelectInputField,
  Fieldset,
  Field,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import {
  adminAddMndaFileSubmitted,
  adminEditCompanyFormClosed,
} from '@hum/icm-app/src/actions';
import { SelectClearbitLocationInput } from '@hum/icm-app/src/components/SelectClearbitLocationInput';
import {
  MNDA_SELECT_OPTIONS,
  COUNTRY_SELECT_OPTIONS_GROUPED,
} from '@hum/icm-app/src/components/constants';
import { MndaOptions } from '@hum/icm-app/src/components/types';
import { Company, CompanyFileDocumentType } from '@hum/types';
import {
  FileManager,
  useFileManager,
} from '@hum/icm-app/src/__DEPRECATED__modules/files';
import {
  FileDropZone,
  pdfOnlyAcceptString,
  pdfOnlyMimeTypes,
  UploadItem,
} from '@hum/icm-app/src/pages/onboarding/ImportDocuments';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { createMndaFile, updateCompany, useApiMutation } from '@hum/api';

const useUploadSection = (fileManager: FileManager, companyId: number) => {
  const flags = useFlags();
  const { mutate: adminCreateMndaFile } = useApiMutation(createMndaFile);
  const onFileUploadClick = () => {};
  const dispatch = useDispatch();
  const onFileUploadChange = fileManager.handleFileChange(
    CompanyFileDocumentType.CompanyMnda,
    { uploadedByAdmin: true },
    () => {
      if (flags.enabled('use-react-query')) {
        adminCreateMndaFile({ companyId });
      } else {
        dispatch(adminAddMndaFileSubmitted({ companyId }));
      }
    }
  );

  return {
    onFileUploadClick,
    onFileUploadChange,
  };
};

type Payload = {
  company: {
    name: string;
    website: string;
    country: string;
    state: string;
    pendingMndaSignOffline: string;
  };
};

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string().trim().required('Company name is required'),
    website: Yup.string()
      .trim()
      .matches(URL_REGEX, 'Please enter a valid URL')
      .required('Website is required'),
    country: Yup.string().trim(),
    state: Yup.string().trim(),
  }),
});

interface UpdateCompanyInformationProps {
  company: Company;
  onSuccess: () => void;
  onCancel: () => void;
  'data-testid': string;
}

const prepareForUpdate = (company: Company): Payload => ({
  company: {
    name: company.name || '',
    website: company.website || '',
    country: company.country || '',
    state: company.state || '',
    pendingMndaSignOffline:
      company.pendingMndaSignOffline || MndaOptions.Default,
  },
});

export const CompanyDetailsV5: React.FC<UpdateCompanyInformationProps> = ({
  company: currentCompany,
  onSuccess,
  onCancel,
  'data-testid': dataTestId,
}) => {
  const flags = useFlags();
  const { mutate: updateCompanyInfo } = useApiMutation(updateCompany, {
    onMutate: () => setLoading(true),
    onSuccess: () => {
      onSuccess();
      setLoading(false);
    },
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileManager = useFileManager({ companyId: currentCompany.id });
  const { onFileUploadClick, onFileUploadChange } = useUploadSection(
    fileManager,
    currentCompany.id
  );
  const { files } = fileManager;

  const mndaFile = files.filter(
    (file) =>
      file.documentType === CompanyFileDocumentType.CompanyMnda &&
      Boolean(file?.meta?.uploadedByAdmin) === true
  );

  const updateInfo = async (payload: Payload) => {
    try {
      setLoading(true);

      // TODO: Create sagas for api calls
      const response = await backend.patch(
        `/companies/${currentCompany.id}`,
        normalize.company.out(payload.company)
      );

      if (response.id) {
        dispatch(adminEditCompanyFormClosed({ companyId: currentCompany.id }));
        toast.success('Updated general information successfully');
        onSuccess();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to update company information.');
    } finally {
      setLoading(false);
    }
  };
  const initialValues = React.useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);
  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: (payload) =>
      flags.enabled('use-react-query')
        ? updateCompanyInfo({
            companyId: currentCompany.id,
            ...payload.company,
          })
        : updateInfo(payload),
  });
  const pendingMndaSignOffline = useField(
    'company.pendingMndaSignOffline',
    'select',
    form
  ).input;
  return (
    <Form onSubmit={form.handleSubmit} testId={dataTestId}>
      <TextInputField
        label="Company Name"
        inputTestId="update-company-information-tabs:general_information_tab:body:company-name"
        autoComplete="chrome-off"
        placeholder="Company name"
        autoFocus
        {...useField('company.name', 'text', form).input}
      />
      <TextInputField
        label="Company website"
        inputTestId="update-company-information-tabs:general_information_tab:body:company-website"
        autoComplete="chrome-off"
        placeholder="https://www.exampleinvestor.io"
        {...useField('company.website', 'text', form).input}
      />
      <SelectInputField
        label="Select a country"
        inputAriaLabel="Select a country"
        data-testid="update:country"
        options={COUNTRY_SELECT_OPTIONS_GROUPED}
        v3
        {...useField('company.country', 'select', form).input}
      />
      <Field
        {...useField('company.state', 'select', form).input}
        v3
        label="Select a state"
        data-testid="update:state"
        disabled={
          useField('company.country', 'select', form).input.value !== 'US'
            ? true
            : false
        }
      >
        {(props) => <SelectClearbitLocationInput {...props} />}
      </Field>
      {!currentCompany.acceptedNDA && (
        <SelectInputField
          label="MNDA"
          {...pendingMndaSignOffline}
          inputAriaLabel="MNDA"
          inputTestId="MNDA"
          infoText={
            'Custom MNDA requires a manual upload to mark it as complete'
          }
          options={MNDA_SELECT_OPTIONS}
        />
      )}

      {form.values.company.pendingMndaSignOffline === MndaOptions.Pending && (
        <Fieldset label="MNDA">
          {!currentCompany.acceptedNDA && (
            <FileDropZone
              onFileUploadClick={onFileUploadClick}
              onFileUploadChange={onFileUploadChange}
              accept={pdfOnlyAcceptString}
              acceptedFileTypesLabel={
                'Upload a signed MNDA in PDF format. Once you upload it, it will be marked as complete'
              }
              acceptedFileMimeTypes={pdfOnlyMimeTypes}
              invalidFileMimeTypeError={'PDF files only'}
            />
          )}

          {mndaFile.map((file) => (
            <UploadItem
              key={file.id}
              file={file}
              fileManager={fileManager}
              hideRemove
            />
          ))}
        </Fieldset>
      )}

      <Box justifySpaceBetween>
        <Button
          type={ButtonType.SECONDARY}
          onClick={() => {
            form.resetForm();
            onCancel();
          }}
          disabled={loading || form.isSubmitting}
          testId="update-company-information-tabs:general_information_tab:body:cancel-button"
        >
          Cancel
        </Button>

        <Button
          submit
          disabled={loading || form.isSubmitting}
          loading={form.isSubmitting}
          testId="update-company-information-tabs:general_information_tab:body:submit-button"
        >
          Update
        </Button>
      </Box>
    </Form>
  );
};
