import React from 'react';
import { Company } from '@hum/types';
import {
  SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS,
  BUSINESS_TYPE_SELECT_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { DescriptionList, InfoSection, Text, TextType } from '@hum/ui-library';
import { getLabelForSelectValue, SelectOption } from '@hum/legacy-ui';
import { money } from '@hum/icm-app/src/utils';

export const renderInvestmentRanges = (ranges: SelectOption[]) => {
  return (
    <div>
      {ranges.map((range, index) => (
        <p key={`${range.value} ${index}`}>
          {
            SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS.find(
              ({ value }) => value === range.value
            )?.label
          }
        </p>
      ))}
    </div>
  );
};

export const renderBusinessTypes = (businessTypes: SelectOption[]) => {
  return (
    <div>
      {businessTypes.map((businessType, index) => (
        <p key={`${businessType.value} ${index}`}>
          {
            BUSINESS_TYPE_SELECT_OPTIONS.find(
              ({ value }) => value === businessType.value
            )?.label
          }
        </p>
      ))}
    </div>
  );
};

export const renderInvestmentTypes = (ranges: SelectOption[]) => {
  return (
    <div>
      {ranges.map((range, index) => (
        <Text type={TextType.BODY_S} key={`${range.value} ${index}`}>
          {
            SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS.find(
              ({ value }) => value === range.value
            )?.label
          }
        </Text>
      ))}
    </div>
  );
};

export const InvestmentProfile = ({ company }: { company: Company }) => {
  const { syndicationInvestor } = company;

  if (!syndicationInvestor) {
    return null;
  }

  return (
    <InfoSection
      title="Investment Profile"
      testId="admin-page:main-area:overview:investment-profile"
    >
      <DescriptionList>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Priority
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:priority">
          <Text type={TextType.BODY_S}>
            {getLabelForSelectValue(
              SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS,
              String(syndicationInvestor.priority)
            ) || '–'}
          </Text>
        </dd>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Investment Ranges
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:investment-ranges">
          <Text type={TextType.BODY_S}>
            {syndicationInvestor.investmentRanges?.length
              ? renderInvestmentRanges(
                  (syndicationInvestor.investmentRanges as unknown[]) as SelectOption[]
                )
              : '–'}
          </Text>
        </dd>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Investment Types
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:investment-types">
          {syndicationInvestor.investmentTypes?.length
            ? renderInvestmentTypes(
                (syndicationInvestor.investmentTypes as unknown[]) as SelectOption[]
              )
            : '–'}
        </dd>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Business types
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:business-types">
          <Text type={TextType.BODY_S}>
            {syndicationInvestor.investmentBusinessTypes?.length
              ? renderBusinessTypes(
                  (syndicationInvestor.investmentBusinessTypes as unknown[]) as SelectOption[]
                )
              : '–'}
          </Text>
        </dd>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Investment yearly revenue min
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:yearly-revenue-min">
          <Text type={TextType.BODY_S}>
            {money(syndicationInvestor.investmentYearlyRevenueMin) || '–'}
          </Text>
        </dd>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Investment yearly revenue max
          </Text>
        </dt>
        <dd data-testid="admin-page:main-area:overview:investment-profile:yearly-revenue-max">
          <Text type={TextType.BODY_S}>
            {money(syndicationInvestor.investmentYearlyRevenueMax) || '–'}
          </Text>
        </dd>
      </DescriptionList>
    </InfoSection>
  );
};
